import "../css/main.css";
import "../css/doodles.css";
import Navbar from "../components/Navbar";

function Doodles() {
    const imgCount = 32;
    const imgs = Array.from({ length: imgCount }, (_, i) => i).sort(() => Math.random() - 0.5);

    return (
        <div>
            {/* Nav Bar */}
            <Navbar className={"position-relative"}/>

            {/* Doodles */}
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <h1 className="roboto-bold">Doodles</h1>
                        <hr/>
                    </div>
                    <div className="col-10">
                        <div className="d-flex flex-wrap">
                        {imgs.map((index) => (
                                <img key={index} className="w-grid py-1" src={`/images/doodles/Doodles-${index+1}.png`} alt={`Doodle ${index}`}/>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Doodles;