import "../css/title.css";

function Title() {
    return (
    <section className="container-fluid">
        <div className="row fill-height align-items-center">
            <div className="col-7 pt-4 ps-4 text-left">
                <h1 id="name" className="loos mb-4">PAUL<br/>GRAY</h1>
                {/* <hr className="w-75"/> */}
                <h2 id="label" className="ps-3 doto-black">3d generalist</h2>
                {/* <div className="text-center">
                    <a href="#about" className="text-decoration-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-chevron-double-down mb-4" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg><br/>
                        <span className="text-dark">About</span>
                    </a>
                </div> */}
            </div>
            <div className="col-5 text-center">
                <img className="w-50 drop-shadow" src="/images/pg_logo.png" alt="Paul Gray Logo"/>
            </div>
        </div>
    </section>
    );
}

export default Title;