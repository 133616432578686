/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import "../css/main.css";
import Navbar from "../components/Navbar";
import Title from "../sections/Title";
import About from "../sections/About";
// import Experience from "../sections/Experience";
import Projects from "../sections/Projects";
// import Gallery from "../sections/Gallery";

function Home() {
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        const hasVisited = sessionStorage.getItem("homePageVisited");

        if (!hasVisited) {
            setShouldAnimate(true);
            sessionStorage.setItem("homePageVisited", "true");
            document.body.classList.add("disable-scroll");

            setTimeout(function() {
                document.body.classList.remove("disable-scroll");
            }, 2000);
        }
        
        window.addEventListener("scroll", reveal);
    }, []);

    function reveal() {
        const reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            let windowHeight = window.innerHeight;
            let elementTop = reveals[i].getBoundingClientRect().top;
            const elementVisible = 300;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    return (
    <div className={`${shouldAnimate ? "entrance" : ""}`}>
        {/* Nav Bar */}
        <Navbar/>

        {/* Title */}
        <Title/>

        {/* About */}
        <About/>

        {/* Experience */}
        {/* <Experience/> */}

        {/* Projects */}
        <Projects/>

        {/* Gallery */}
        {/* <Gallery/> */}

        {/* Footer */}
        <div className="text-center marginbtm bg-dark text-light">
            <p className="h6 pt-4">Built with ReactJS and Bootstrap.</p>
            <p className="h6 pb-4">Graphics created in Adobe Illustrator. Brand icons from FontAwesome.</p>
        </div>
    </div>
    );
}

export default Home;