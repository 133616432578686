import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import Gallery from "./pages/Gallery";
import Doodles from "./pages/Doodles";

function App() {
    return (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/experience" element={<Experience/>}></Route>
            <Route path="/projects" element={<Projects/>}></Route>
            <Route path="/gallery" element={<Gallery/>}></Route>
            <Route path="/doodles" element={<Doodles/>}></Route>
        </Routes>
    </Router>
    );
}

export default App;
