import "../css/navbar.css";

function Navbar({className}) {
    return (
    <div>
        <nav className={`navbar fixed-top navbar-expand-md navbar-light ease-1s mx-4 my-3 ${className}`} id="navbar">
            <div className="container-fluid">
                <a className="navbar-brand bold active" aria-current="page" href="/">Home</a>
                <button className="navbar-toggler collapsed" id="navtoggle" type="button" data-bs-toggle="collapse" data-bs-target="#navcontent" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon top-bar rounded-pill"></span>
                    <span className="toggler-icon middle-bar rounded-pill"></span>
                    <span className="toggler-icon bottom-bar rounded-pill"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcontent">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item"><a className="nav-link" href="/experience/">Experience</a></li>
                        <li className="nav-item"><a className="nav-link" href="/projects/">Projects</a></li>
                        <li className="nav-item"><a className="nav-link" href="/gallery/">Gallery</a></li>
                        <li className="nav-item"><a className="nav-link" href="/doodles/">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-highlighter mb-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.096.644a2 2 0 0 1 2.791.036l1.433 1.433a2 2 0 0 1 .035 2.791l-.413.435-8.07 8.995a.5.5 0 0 1-.372.166h-3a.5.5 0 0 1-.234-.058l-.412.412A.5.5 0 0 1 2.5 15h-2a.5.5 0 0 1-.354-.854l1.412-1.412A.5.5 0 0 1 1.5 12.5v-3a.5.5 0 0 1 .166-.372l8.995-8.07zm-.115 1.47L2.727 9.52l3.753 3.753 7.406-8.254zm3.585 2.17.064-.068a1 1 0 0 0-.017-1.396L13.18 1.387a1 1 0 0 0-1.396-.018l-.068.065zM5.293 13.5 2.5 10.707v1.586L3.707 13.5z"/>
                            </svg>
                        </a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    );
}

export default Navbar;